
.flowers{
    padding: 50px;
    height: max-content;
}

.flowers-header{
    padding-bottom: 20px;
    border-bottom: 1px solid var(--clr-dark);
    color: var(--clr-hl1);
}

.flowers-subheader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.flowers-search{
    border: 1px solid var(--clr-dark);
    font-size: var(--fs-body);
    padding: 1em;
    height: 2em;
    width: 16em;
    border-radius: 1em;
}

.flowers-category-selectors-container{
    display: flex;
    width: min-content;
    align-items: center;
}

.flowers-head-category-selector{
    width: 15em;
    margin: 10px 10px 10px 0;
    border: 1px solid var(--clr-dark);
    background-color: lightgray;
    font-size: var(--fs-body);
    padding: 5px;
}

.flowers-sub-category-selector{
    width: 15em;
    margin: 10px 10px 10px 0;
    border: 1px solid var(--clr-dark);
    background-color: lightgray;
    font-size: var(--fs-body);
    padding: 5px;
}

.flowers-category-selector-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4em;
    height: 2em;
    border: 1px solid var(--clr-dark);
    border-radius: 1em;
    background-color: var(--clr-hl1);

}

.flowers-content{
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    column-gap: 20px;
    row-gap: 20px;
    width: 100%;
    height: min-content;

}

.flowers-item{
    width: 100%;
}

.flowers-item-img{
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 20em;
}

.flowers-item-img-container{
    position: relative;
    width: 100%;
    height: 20em;
    border: 1px solid var(--clr-dark);

}

.flowers-item-price{
    color: var(--clr-hl1);
}

@media screen and (max-width: 1024px) {
    .flowers-content{
        grid-template-columns: repeat(3, 1fr);
        column-gap: 10px;
        row-gap: 10px;
    }

    .flowers-item-img{
        height: 15em;
    }

    .flowers-subheader{
        flex-direction: column;
        align-items: start;
    }

    .flowers-head-category-selector{
        width: 10em;
    }

    .flowers-sub-category-selector{
        width: 10em;
    }
}

@media screen and (max-width: 768px) {
    .flowers{
        padding: 10px;
    }
    .flowers-content{
        grid-template-columns: repeat(2, 1fr);
        column-gap: 10px;
        row-gap: 10px;
    }

}