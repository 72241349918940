.profile-page{
    padding: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.profile-details{
    border: 3px solid var(--clr-hl1);
    padding: 50px;
    width: 55vw;
}

.profile-title-container{
    border-bottom: 1px solid var(--clr-dark);
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.profile-info{
    margin: 30px 0 30px 0;
}

.profile-img{
    width: 7rem;
    height: 7rem;
    background-color: var(--clr-light);
    border: 3px solid var(--clr-hl1);
    border-radius: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--clr-hl1);
}

.profile-info-item{
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0 5px 0;
}

.profile-info-input{
    margin-left: 20px;
    border: 1px solid var(--clr-dark);
    padding: 3px;
    border-radius: 7px;
    width: auto;
}



.profile-info-submit-buttons{
    display: flex;
}

.profile-info-submit{
    width: 80px;
    height: 30px;
    background-color: var(--clr-hl1);
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
    margin-left: 0;
    font-size: calc(var(--fs-body) * 0.8);
}

.profile-info-sign_out{
    width: 80px;
    height: 30px;
    background-color: red;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
    font-size: calc(var(--fs-body) * 0.8);
}

.profile-info-username{
    margin-left: 20px;
}
/* sidebar */
.profile-side{
    border: 3px solid var(--clr-hl1);
    width: 20vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px;
}

.side-option{
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;
    cursor: pointer;
    padding: 10px;
}

.side-option-icon{
    margin-right: 10px;
}

.side-option-selected{
    border: 1px solid var(--clr-dark);
    border-radius: 5px;
}

@media screen and (max-width: 780px) {

    .profile-page{
        padding: 10px;
        flex-direction: column;
    }

    .profile-side{
        width: auto;
        flex-direction: row;
        margin-bottom: 5vh;
        padding: 15px;
    }

    .side-option{
        justify-content: center;
        height: 35px;
        padding: 2px;
    }

    .side-option-icon{
        margin-right: 3px;
    }

    .profile-details{
        width: 100%;
        padding: 30px;
    }
}


/* address child */
.address-form{
    display: flex;
    flex-direction: column;
}

.address-form-item{
    display: block;
}

.address-form-text{
    padding: 10px;
    width: 100%;
    border: 1px solid var(--clr-dark);
    border-radius: 10px;
    margin: 15px 0 15px 0;
}

/* purchases page */
.purchases-none{
    width: auto;
    height: auto;
    display: flex;
    border: 1px solid var(--clr-dark);
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    min-height: 10vh;
}

.purchase-item{
    width: auto;
    height: auto;
    margin-top: 20px;
    min-height: 10vh;
    border: 1px solid var(--clr-dark);
    padding: 2em;

}

.purchase-units{
    border-top: 1px solid var(--clr-dark);
    border-bottom: 1px solid var(--clr-dark);

    margin: 1em 0 1em 0;
}

.purchase-unit{
    height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.purchase-unit-img{
    height: 100%;
}

@media screen and (max-width: 768px) {
    .purchase-unit{
        flex-direction: column;
        justify-content: space-around;
    }
    .purchase-unit-img{
        height: 60%;
    }
}