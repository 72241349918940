

.checkout{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    margin-top: 10px;
}

.checkout-main{
    height: 35em;
    border: 1px solid black;
    width: 30em;
    padding: 30px;
    overflow-y: auto;
}

.checkout-continue-btn{
    border: 1px solid var(--clr-dark);
    background-color: var(--clr-hl1);
    width: 7em;
    height: 3em;
    border-radius: 1.5em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--fs-body);
}

.checkout-continue-btn:disabled{
    background-color: grey;
}

.checkout-back-btn{
    border: 1px solid var(--clr-dark);
    width: 7em;
    height: 3em;
    border-radius: 1.5em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--fs-body);
}

.checkout-btn-container{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 0 10px 0;
    width: 100%;
}

.checkout-continue-container{
    display: flex;
    justify-content: end;
    flex-direction: row;
    padding: 10px 0 10px 0;
    width: 100%;
}

/* delivery */
.checkout-delivery-form{
    margin: 10px 0 0 0;
}

.checkout-delivery-table{
    border-spacing: 10px;
    border-collapse: separate;
}

.checkout-delivery-input{
    border: 1px solid black;
    height: 40px;
    border-radius: 5px;
    width: 100%;
    padding: 5px;
}

.checkout-delivery-selection{
    width: 60%;
    margin: 10px 0 10px 0
}

.checkout-delivery-selection-btn{
    border: 1px solid var(--clr-dark);
    width: 5em;
    height: 2em;
    margin: 0 10px 0 10px;
    border-radius: 0.5em;
    cursor: pointer;
    font-size: var(--fs-body);
}

.checkout-delivery-date{
    margin: 10px;
}

.checkout-delivery-textarea{
    resize: none;
    height: 200px;
}

/* details */

.checkout-details{
    height: 100%;
    display: flex;
    flex-direction: column;
}
.checkout-details-row{
    display: flex;
    justify-content: space-between;
}

.checkout-details-table{
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 10px 0 10px 0;
    flex-grow: 1;
    border-bottom: 1px solid var(--clr-dark);
    border-top: 1px solid var(--clr-dark);
}

.checkout-details-summary-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.checkout-total{
    border-top: 1px solid var(--clr-dark);
    margin: 5px 0 5px 0;
}

/* payment page */
.checkout-payment-title{
    padding: 10px 0 10px 0;
    border-bottom: 1px solid var(--clr-dark);
}

.checkout-payment-paypal{
    margin: 10px 0 10px 0;
}

@media screen and (max-width: 780px){
    .checkout-main{
        height: 100%;
        border: none;
        padding: 10px;
    }

}