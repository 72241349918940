

.flower-detail{
    background-color: rgba(0, 0, 0, 0.3);
    left: 0;
    width: auto;
    height: 80vh;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    background-color: var(--clr-light);
    padding: 2em;
}

.flower-page-img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.flower-page-img-container{
    width: 40%;
    height: 100%;
}

.flower-page-wrapper{
    padding: 1em;
    width: 60%;
}

.flower-page-title{
    text-transform:uppercase;
    color: var(--clr-hl1);
    border-bottom: 1px solid var(--clr-dark);
}

.flower-page-desc{
    margin: 2em 0 2em 0;
    padding: 2em;
    border: 1px solid var(--clr-dark);
    max-height: 50%;
    word-wrap: break-word;
    display: table;
    width: 100%;
    table-layout: fixed;
}

.flower-page-amt{
    display: flex;
    width: 100%;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.flower-page-quantity{
    border: 1px solid var(--clr-dark);
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 2em;
}

.flower-page-quantity-btn{
    cursor: pointer;
}

.flower-page-submit{
    border: 1px solid var(--clr-dark);
    background-color: var(--clr-hl1);
    border-left: 0;
    width: 100%;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.flower-page-backBtn{
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flower-page-horizontal-container{
    margin: 10px 0 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;  
    align-items: baseline;
}


/* variants */
.flower-variants{
    border-left: 1px solid var(--clr-dark);
    padding-left: 1rem;
    min-width: 70%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
}

.flower-variants-container{
    display: flex;
    justify-content: left;
    padding: 1rem 1rem 0 0;
    width: auto;
    max-width: 100%;
    flex-wrap: wrap;
}

.flower-variants-item{
    width: 5em;
    height: 5em;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--clr-hl1);
    font-weight: 500;
    border: 2px solid var(--clr-hl1);
    cursor: pointer;
}

.flower-variants-item-name{
    font-weight: 550;
}

.flower-variants-selected{
    width: 5em;
    height: 5em;
    margin: 0 5px 0 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--clr-light);
    font-weight: 500;
    background-color: var(--clr-hl1);
    cursor: pointer;

}

@media screen and (max-width: 768px) {
    .flower-detail{
        flex-direction: column;
        padding: 1em;
        height: 100%;
        overflow: scroll;
    }

    .flower-page-img-container{
        width: 100%;
        height: 30%;
    }

    .flower-page-wrapper{
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
    }

    .flower-page-desc{
        margin: 1em 0 1em 0;
    }
    .flower-page-price{
        margin: 1em 1em 1em 0;
    }

}

/* addons */

.flower-addons{
    border-top: 1px solid var(--clr-dark);
    padding: 2em;
}

.flower-addons-title{
    margin: 50px 0 50px 0;
}

.flower-addon-img{
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 15em;
}

.flower-addons-grid{
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: auto;
}

.flower-addon-price{
    color: var(--clr-hl1);
}

@media screen and (max-width: 768px) {

    .flower-addons-grid{
        grid-template-columns: repeat(2, 1fr);
    }

}