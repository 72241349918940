.contact{
    padding: 50px;
}

.contact-header{
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.contact-title{
    font-size: 5em;
    color: var(--clr-hl1);
}

.contact-quote{
    font-size: 3em;
    font-family: Thesignature;
}

.contact-line{
    width: 100%;
    height: 1px;
    background-color: var(--clr-dark);
}

.contact-form{
    margin: 2em 0 2em 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.contact-form-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.contact-form-item{
    width: 30%;
    height: min-content;
    display: flex;
    flex-direction: column
}

.contact-form-box{
    width: 100%;
    height: 25px;
    margin: 10px 0 10px 0;
    padding: 5px;
    border: 1px solid var(--clr-dark);
    border-radius: 5px;
}

.contact-form-msgItem{
    display: flex;
    flex-direction: column;
}

.contact-form-msgBox{
    min-width: 100%;
    max-width: 100%;
    min-height: 40px;
    max-height: 300px;
    margin: 10px 0 10px 0;
    width: 100%;
    border: 1px solid var(--clr-dark);
    border-radius: 5px;
}

.contact-form-method{
    display: flex;
    flex-direction: column;
    width: 30%;
}

.contact-form-dd{
    width: 100%;
    margin: 10px 0 10px 0;
    border: 1px solid var(--clr-dark);
}

.contact-form-submit{
    margin: 5vh 0 5vh 0;
    width: 150px;
    height: 50px;
    border-radius: 25px;
    background-color: var(--clr-hl1);
    font-size: var(--fs-body);

    cursor: pointer;
}

@media screen and (max-width: 960px) {
    .contact{
        padding: 2em;
    }
    .contact-title{
        border-bottom: 1px solid var(--clr-dark);
        margin: 0.25em 0 0.25em 0;
        width: 100%;
        font-size: 4em;
    }
    .contact-header{
        flex-direction: column;
        justify-content: center;
    
    }
    .contact-line{
        display: none;
    }
}