.cart{
    padding: 50px;
    overflow: hidden;
}

.cart-header{
    display: flex;
    justify-content: center;
    border-bottom: 1px solid var(--clr-dark);

}

.cart-title{
    color: var(--clr-hl1);
}

.cart-content{
    width: 100%;
}

.cart-content-header-wrapper{
    width: 100%;
    height: 15vh;
    border-bottom: 1px solid var(--clr-dark);
}

.cart-content-header{
    width: 25%;
    font-size: var(--fs-body);
}

.cart-item{
    width: 100%;
    position: relative;
}

.cart-item-img{
    padding: 30px 0 30px 0;
    height: 40vh;
}

.cart-item-title{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 30px;
}

.cart-item-quantity{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    margin: 30px;
}

.cart-item-quantity-btn{
    width: 50px;
    height: 50px;
    background-color: transparent;
}

.cart-item-total{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 30px;
}

.cart-item-btn{
    width: 120px;
    height: 40px;
    background-color: transparent;
    display: flex;
    color: var(--clr-hl1);
    align-items: center;
}

.cart-footer{
    border-top: 1px solid var(--clr-dark);
    display: flex;
    width: 100%;
    height: 20vh;
    flex-direction: column;
    justify-content: space-around;
    align-items: end;
}

.cart-order-btn{
    border: 1px solid var(--clr-dark);
    background-color: var(--clr-hl1);
    width: 7em;
    height: 3em;
    border-radius: 1.5em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--fs-body);
}

.cart-order-btn:focus{
    filter: brightness(0.5);
}

.cart-empty{
    border-bottom: 1px solid var(--clr-dark);
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cart-item-mobile-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.cart-item-mobile-quantity{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    border: 1px solid var(--clr-dark);
}

.cart-item-quantity-num{
    width: 5em;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid var(--clr-dark);
    border-right: 1px solid var(--clr-dark);

}

.cart-item-quantity-btn{
    width: 3em;
    height: 3em;
    background-color: lightgrey;
}

.cart-item-quantity-btn:focus{
    background-color: grey;
}

@media screen and (max-width: 780px) {
    .cart{
        padding: 10px;
    }
    .cart-item-img{
        width: 7em;
        object-fit: contain;
    }

    
}

