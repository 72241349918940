@font-face {
  font-family: Thesignature;
  src: url('./assets/fonts/Thesignature.otf');
}

h1{
  font-weight: 50;
  font-size: var(--fs-h1);
}

h2{
  font-weight: 50;
  font-size: var(--fs-h2);
}

h3{
  font-weight: 100;
  font-size: var(--fs-h3);
}

p{
  font-size: var(--fs-body);
}


body {
  font-size: var(--fs-body);
  font-family: 'Radley', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  scrollbar-gutter: stable;
}

input {
  font-family: 'Radley', serif;
}

select{
  font-family: 'Radley', serif;
}

table{
  border-collapse: collapse;
}

button{
  font-family: 'Radley', serif;
  font-size: var(--fs-body);
  cursor: pointer;
}

*{
  box-sizing: border-box;
  margin: 0;
  border: 0;
  padding: 0;
}

textarea{
  font-family: 'Radley', serif;
}


.link{
  text-decoration: none;
  color: inherit;
}

/* media queries follow this: 

320px — 480px: Mobile devices.
481px — 768px: iPads, Tablets.
769px — 1024px: Small screens, laptops.
1025px — 1200px: Desktops, large screens.
1201px and more — Extra large screens, TV.

*/

@media screen and (max-width: 480px) {
  :root{
    --fs-h1: 30px;
    --fs-h2: 22px;
    --fs-h3: 17px;  

    --fs-body: 13px;
  }

}

@media screen and (min-width: 481px) and (max-width: 1024px) {
  :root{
    --fs-h1: 35px;
    --fs-h2: 27px;
    --fs-h3: 20px;  
    --fs-body: 15px;

  }

}

@media screen and (min-width: 1025px) {
  :root{
    --fs-h1: 50px;
    --fs-h2: 34px;
    --fs-h3: 22px;
    --fs-body: 17px;
  }
}

:root{
  --clr-dark: black;
  --clr-light: white;
  --clr-hl1: #E1A730;
  --clr-hl2: #C1FFF7;

  --header_height: 72px;
}

