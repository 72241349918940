.header{
    width: 100%;
    padding: 20px;
    height: var(--header_height);
    border-bottom: 3px solid var(--clr-hl1);
    z-index: 5;
    top: 0;
    background-color: var(--clr-light);
    position: sticky;
}

.header-logo{
    color: var(--clr-hl1);
}

.header-full_menu{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-side{
    position: absolute;
}

.header-side_menu{
    width: 100vw;
    height: 100vh;
    position: absolute;
    float: left;
    left: -20px;
    top: -20px;
    background-color: var(--clr-light);
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 5;
}

.header-menu_btn{
    cursor: pointer;
}

.header-side-enter{
    transform: translateY(100%);
}

.header-side-enter-active{
    transform: translateY(0);
    transition: transform 200ms;
}

.header-side-exit{
    transform: translateY(0);
}

.header-side-exit-active{
    transform: translateY(100%);
    transition: transform 200ms;
}

.header-side-close{
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
}

.header-side-options{
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style-type: none;
    justify-content: space-around;
    height: 70%;
    width: 100%;
}

.header-side-option{
    font-size: 30px;
    color: var(--clr-hl1);
    text-align: center;
}

.header-side-option-enter{
    font-size: 30px;
    color: var(--clr-hl1);
}

.header-side-option-enter-done{
    color: transparent;
    font-size: 40px;
    background-image: linear-gradient(to left, var(--clr-hl1), var(--clr-hl2));
    background-clip: text;
    -webkit-background-clip: text;
    animation: flow_colour 2s linear infinite;
    background-size: 500%;
    transition: font-size 200ms;
}

.header-side-option-exit{
    color: transparent;
    font-size: 40px;
    background-image: linear-gradient(to left, var(--clr-hl1), var(--clr-hl2));
    background-clip: text;
    -webkit-background-clip: text;
    animation: flow_colour 2s linear infinite;
    background-size: 500%;
}

.header-side-option-exit-done{
    color: var(--clr-hl1);
    font-size: 30px;
    background-image: none;
    background-clip: none;
    -webkit-background-clip: none;
    animation: none;
    background-size: 500%;
    transition: font-size 200ms;
}

@keyframes flow_colour{
    0%{
        background-position: 0 100%;
    }
    50%{
        background-position: 100% 0;
    }
    100%{
        background-position: 0 100%;
    }
}

.header-side-icons{
    display: flex;
    justify-content: space-between;
    width: 70px;
    position: absolute;
    top: 30px;
    left: 30px;
}


.header-wrap{
    position: relative;
    background-color: var(--clr-light);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.header-options{
    text-decoration: none;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header-option{
    margin: 0 8px 0 8px;
    cursor: pointer;
    font-size: 17px;
    display: inline-block;
}

.header-option > a{
    text-decoration: none;
    color: inherit;
}

.header-icons{
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header-icon{
    margin: 0 10px 0 10px;
    cursor: pointer;
}

.header-dd-menu-wrapper{
    display: none;
    position: absolute;
    z-index: 5;

}

.header-dd-menu{
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: var(--clr-light);
    border: 3px solid var(--clr-hl1);
    
}

.header-dd-menu-item{
    height: 40px;
    background-color: var(--clr-light);
    display: flex;
    align-items: center;
    padding: 20px 20px 20px 0px;
    transition: text-decoration-color 1s;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0);
}

.header-dd-menu-item:hover{
    text-decoration-color: black;

}

.header-dd-menu-item > a{
    color: inherit;
    text-decoration: none;
}

.header-dd:hover .header-dd-menu-wrapper{
    display: block;
    padding-top: 50px;
}

@media screen and (max-width: 1024px) {

}

/* error page */

.error-page {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

/* footer */

.footer{
    margin: 10vh 50px 0 50px;
    height: 200px;
    border-top: 3px solid var(--clr-hl1);
    display: flex;
    position: relative;
    justify-content: center;
}

.footer-contact{
    width: 30%;
}

.footer-socials{
    width: 30%;
}

.footer-logo{
    width: 30%;
}

.footer-wrapper{
    margin-top: 50px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-contact-item{
    display: flex;
    margin-top: 10px;
}

.footer-contact-icon{
    margin-right: 10px;
}

.footer-socials-icons{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
}

.footer-logo{
    display: flex;
    justify-content: end;
}

.footer-logo-img{
    height: 100px;
}

@media screen and (max-width: 780px) {
    .footer{
        margin: 10px;
        height: 50vh;
    }

    .footer-wrapper{
        height: 100%;
        margin-top: 10px;
        flex-direction: column;
        justify-content: space-around;
    }



    .footer-contact{
        width: 100%;
    }
}

/* loading screen */
.loading{
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    background-color: var(--clr-light);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 10;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

.loading-rose{
    width: 50px;
    height: 50px;
    animation: rotate 3s infinite linear;
}


