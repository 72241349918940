.login{
    padding: 50px;
    overflow: hidden;
}

.login-wrapper{
    background-image: url(/src/assets/img/login-bg.jpg);
    height: 75vh;
    width: 100%;
    padding: 50px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;

}

.login-content{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 80%;
    width: 30%;
    position: absolute;
}

.login-title{
    color: var(--clr-hl1);
}

.login-form{
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.login-form-item{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 20%;
}

.login-form-text{
    height: 30px;
    border-radius: 5px;
    border: 1px solid var(--clr-dark);
    padding: 5px;
}

.login-form-button{
    width: 100%;
    height: 40px;
    border-radius: 15px;
    background-color: var(--clr-hl1);
    font-size: var(--fs-body);
    cursor: pointer;
    margin: 15px 0 10px 0;
}

.login-create{
    color: var(--clr-hl1);
    display: flex;
    align-items: center;
    width: max-content;
    cursor: pointer;
}

.login-content-create{
    opacity: 0;
}

/* signup values */
.signup-table{
    border-spacing: 10px;
    border-collapse: separate;
}

.signup-form-textarea{
    min-width: 100%;
    max-height: 70px;
    resize: none;
    border-radius: 5px;
    border: 1px solid var(--clr-dark);
    padding: 5px;
}

.signup-form-cell{
    padding: 5px;
}

.signup-form-item{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.signup-form-text{
    border-radius: 5px;
    border: 1px solid var(--clr-dark);
    padding: 3px;
}

/* animations */

.login-anim{
    width: 100%;
    height: 100%;
}

.login-anim-enter {
    opacity: 0;
    transform: translateX(20%);
}
.login-anim-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.login-anim-exit {
    opacity: 1; 
}
.login-anim-exit-active {
    opacity: 0;
    transform: translateX(-20%);
    transition: opacity 300ms, transform 300ms;
}

@media screen and (max-width: 780px) {
    .login{
        padding: 0;
    }

    .login-wrapper{
        height: 90vh;
        padding: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size:cover;
        background-position-x: 70%;
    }
    .login-content{
        width: 90%;
    }

    .login-form-text{
        width: 100%;
    }

    .signup-form{
        position: relative;
        width: 100%;
    }

    .signup-table{
        border-collapse: collapse;
        border-spacing: 5px;
        width: 100%;

    }

    .signup-form-cell{
        width: 40px;

    }

    .signup-form-item{
        width: 100%;
    }

    .signup-form-text{
        width: 100%;
    }

}