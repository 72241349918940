/* Hero */

.hero{
    width: 100%;
    height: 85vh;
    overflow: hidden;
}

.hero-bgImg-wrapper{
    position: absolute;
    width: 100%;
    height: 85%;
    z-index: 0;
    border-bottom: 3px solid var(--clr-hl1);
}

.hero-bgImg{
    background-image: url('/src/assets/img/heroTulips.jpg');
    background-size: cover;
    width: 100%;
    height: 100%;
    filter: brightness(0.5);
}

.hero-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 3;
    list-style: none;
}

.hero-title{
    position: relative;
    font-size: 7em;
    text-align: center;
    font-weight: 100;
    color: var(--clr-hl1);
}

.hero-subtitle{
    position: relative;
    color: var(--clr-hl2);
    font-family: Thesignature;
    font-size: 4em;
}

.hero-btn{
    cursor: pointer;
    margin-top: 70px;
    position: relative;
    border: 3px solid var(--clr-hl1);
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.hero-btn-txt{
    color: var(--clr-hl1);
    font-size: 17px;
    font-weight: 50;
}

@media screen and (max-width: 780px) {
    .hero-title{
        font-size: 4em;
    }
}
/* Categories */

.categories{
    height: 100vh;
}

.categories-title{
    color: var(--clr-hl1);
}

.categories-wrapper{
    height: 75%;
    padding: 20px;
}

.categories-list{
    list-style: none;
    height: 100%;
    display: flex;
    justify-content: space-around;
}

.categories-item{
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 1px solid var(--clr-light);
}

.categories-item-title{
    font-family: Thesignature;
    font-size: 3em;
    border-bottom: 1px solid var(--clr-dark);

}

.categories-item-separation{
    width: 2px;
    height: 100%;
    background-color: var(--clr-hl1);
}

.categories-item-img{
    width: 10em;
    height: 10em;
    object-fit: cover;

}

.categories-item-text-wrapper{
    width: 75%;
    display: flex;
    justify-content: center;
    
}

.categories-item-arrow{
    width: 50px;
    cursor: pointer;
}

@media screen and (min-width: 780px) {
    .categories{
        margin: 25px;
    }
    .categories-title{

    }
}

@media screen and (max-width: 780px) {
    .categories{
        height: auto;
    }

    .categories-title{
        margin: 50px;
    }
    .categories-wrapper{
        width: 100%;
        height: auto;
    }
    .categories-list{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fill, 1fr);
    }

    .categories-item-title{
        z-index: 3;
        color: var(--clr-light);
        border-bottom: 1px solid var(--clr-light);
        text-align: center; 
        line-height: 0.5em;
    }

    .categories-item{
        width: 100%;
        height: 50vh;
        position: relative;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }

    .categories-item-img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(0.5)
    }

    .categories-item-text-wrapper{
        margin-top: 10px;
        z-index: 3;
        color: var(--clr-light);
    }

    .categories-item-text{
        text-align: center;
    }

    .categories-item-btn{
        z-index: 3;
        color: var(--clr-light);
        margin: 10px;
    }
}

/* Popular */
.popular{
    width: 100%;
    position: relative;
}

.popular-title{
    color: var(--clr-hl2);
    z-index: 3;
    float: left;
}
.popular-title-container{
    height: 25vh;
    border-top: 3px solid var(--clr-hl1);
    border-bottom: 3px solid var(--clr-hl1);
    position: relative;
}

.popular-title-wrapper{
    height: 25vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;

}

.popular-title-bgImg{
    position: absolute;
    background-image: url('/src/assets/img/popularBg.jpg');
    background-size: cover;
    background-position-y: 50%;
    width: 100%;
    height: 100%;
    filter: brightness(0.7);
}

.popular-content{
    padding: 50px;
    width: 100%;
    display: grid;
    grid-template-rows: repeat(auto-fill, 1fr);
    column-gap: 50px;
    row-gap: 50px;

}

.popular-item-img{
    width: 100%;
    height:60vh;
    object-fit: cover;
    margin-bottom: 20px;
    border: 1px solid var(--clr-dark);
}

.popular-item-name-wrapper{
    padding-top: 20px;
    border-top: 1px solid var(--clr-dark);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.popular-item-name{
    font-size: 20px;
}

.popular-item-price{
    font-size: 20px;
    color: var(--clr-hl1)
}

@media screen and (min-width: 780px) {
    .popular-content{
        grid-template-columns: repeat(3, 1fr);

    }
}


@media screen and (max-width: 780px) {
    .popular-content{
        grid-template-columns: repeat(2, 1fr);
        padding: 2em;
        column-gap: 1em;
        row-gap: 2em;
    }

    .popular-item-img{
        height: 30vh;
    }
}
